import { ViewServices } from './ViewServices.js'
import { EventEmitter } from './EventEmitter.js'
import { Api } from 'ModelBundle'
import { Auth } from 'AuthBundle'

/**
 * Service worker methods
 */
export const ServiceWorker = {
	registration: null,
	emitter: new EventEmitter(),
	/**
	 * Post message to service worker
	 * @param  {string} action
	 * @param  {Object} options
	 * @return {EventEmitter}
	 */
	postMessage(action, options = {}) {
		if(!this.registration) throw new Error('Unable to access registration')
		const emitter = new EventEmitter()
		const channel = new MessageChannel()
		channel.port1.onmessage = event => {
			emitter.emit(event.data)
		}
		this.registration.active.postMessage({
			action: action,
			options: options
		}, [channel.port2])
		return emitter
	},
	/**
	 * Do not call from external
	 * @param {ServiceWorkerRegistration} registration
	 */
	_setRegistration(registration) {
		this.registration = registration
		navigator.serviceWorker.addEventListener('message', e => this.emitter.emit(e.data))
		this.checkRegistrations()
		Auth.events.login.subscribe(() => this.checkRegistrations())
		Auth.events.logout.subscribe(() => this.postMessage('unsubscribeNotification'))
	},
	/**
	 * Check service worker registrations, reregister if necessary
	 */
	checkRegistrations() {
		const yesterday = Date.now() - (24 * 60 * 60 * 1000)
		const lastCheck = localStorage.getItem('Interface.lastSWRegistrationCheck')
		if(Auth.isAuthenticated() && Notification.permission === 'granted' && (!lastCheck || lastCheck < yesterday)) {
			Api.post('/api/structure/', {
				'context("result"):s.internals.notificationusersettings.checkEndpoints()': {result: 'local.result'}
			}).then(() => {
				const timeout = setTimeout(() => {
					subscription.unsubscribe()
					this.registerEndpoint()
				}, 3000)
				const subscription = this.emitter.subscribe(e => {
					if(e.action == 'ping') {
						subscription.unsubscribe()
						clearTimeout(timeout)
					}
				})
			})
		}
	},
	registerEndpoint() {
		Api.post('/api/structure/', {
			'context("result"):s.internals.notificationusersettings.getVapidPublic()': {result: 'local.result'}
		}).then(resp => {
			const padding = '='.repeat((4 - (resp.result.length % 4)) % 4)
			const base64 = (resp.result + padding).replace(/-/g, '+').replace(/_/g, '/')

			const rawData = window.atob(base64)
			const buffer = new Uint8Array(rawData.length)
			for(let i = 0; i < rawData.length; ++i) buffer[i] = rawData.charCodeAt(i)

			this.postMessage('requestNotification', {
				key: buffer
			}).once(endpoint => {
				let req = {}
				req['context("result"):s.internals.notificationusersettings.registerEndpoint(' + JSON.stringify(endpoint) + ')'] = {
					result: 'local.result'
				}
				Api.post('/api/structure/', req).then(() => {
					localStorage.setItem('Interface.lastSWRegistrationCheck', Date.now())
				})
			})
		})
	}
}

ViewServices.serviceWorker = ServiceWorker

navigator.serviceWorker.ready.then(registration => ServiceWorker._setRegistration(registration))