<template>
	<div class="newSignatureWindowContainer shadow" :style="{width: isFullscreen ? '100%' : (mobile ? 'calc(100% + 50px)' : (opened ? '650px' : '300px'))}" :class="{mobile: mobile, fullscreen: isFullscreen}">
		<div class="header" @click="mobile ? null : opened = !opened">
			<icon class="legend">drafts</icon>
			<h5>{{ formValue?.name ? formValue.name : $t('documentsignature.newmessage') }}</h5>
			<button type="button" class="bl-icon-button" v-if="controls.fullscreen && opened" @click="toggleFullscreen($event)">{{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}</button>
			<button type="button" class="bl-icon-button" @click="close($event)">close</button>
		</div>
		<div class="body" :style="{display: opened || isFullscreen ? null : 'none'}">
			<BlForm name="documentsignature" @additionalData="document = $event" @modelValue="this.formValue = $event" ref="form" url="documentsignature" :context="{ documentArgs: documentArgs, documentRef: documentRef, object: object, name: name, body: body }" :beforeSubmit="onFormSubmit" @submitted="controls.close(true)">
				<div v-if="document.exist" style="display: flex; align-items: center; color: var(--bl-warning); background-color: var(--bl-warning-bg); white-space: pre-wrap; font-weight: 500; padding: 10px;">
					{{ $t('documentsignature.errors.existingProcess') }}
				</div>
				<div class="subjectContainer"><BlFormField name="name" /></div>
				<hr style="margin-top: 6px;" />
				<div class="favoriteContactDisplay" v-if="recommendedRecipients.length > 0">
					<span class="favoriteContactLabel">{{ $t('documentsignature.recommendedRecipients') }} </span>
					<span class="bl-chip favoriteContact" style="cursor: pointer;" v-for="(favorite, index) in recommendedRecipients" :key="index" @click="addFromFavorite(index, favorite)">{{ favorite.name }}</span>
				</div>
				<div class="recipientContainer">
					<div class="bl-input">
						<span style="margin-right: 3px;" >{{ $t('documentsignature.to') }}</span>
						<div class="recipientInfo" style="cursor: pointer;" @click="modifyRecipient(index, recipient)" v-for="(recipient, index) in recipientArray" :key="index">
							<span class="contactHeader" :style="{backgroundColor: '#' + getColor(recipient.name)}">{{ recipient.name[0] }}</span> 
							<span>{{ recipient.name }}</span>
							<icon v-if="recipient.phone" v-bl-tooltip="recipient.phone" class="icon">phone</icon>
							<icon v-if="recipient.email" v-bl-tooltip="recipient.email" class="icon">email</icon>
						</div>
						<BlButton :label="$t('documentsignature.addRecipient')" class="dense outlined" icon="add" @click="addNewRecipient()" />
					</div>
				</div>
				<hr style="margin-top: 20px;" />
				<div style="flex: 1;" class="bodyContainer">
					<BlFormField name="body" />
				</div>
				<hr style="margin-bottom: -2px; margin-top: -4px;"/>
				<div class="attachments" style="margin-left: 5px;" @click="openPreview()">
					<img src="https://static.mixsuite.fr/file_icons/pdf_color.svg">
					{{ document.objectName + '.pdf'  }}
				</div>
				<hr style="margin: 0px 0 0 5px;" />
				<div class="bottomActions">
					<div style="display: none;"><BlFormField name="counterSignature" /></div>
					<div class="counterSignature" :class="{active: $refs.form.form.getChild('counterSignature').value}" @click="$refs.form.form.getChild('counterSignature').setValue(!$refs.form.form.getChild('counterSignature').value)" v-bl-tooltip="$t('documentsignature.property.counterSignature')">
						<button type="button" class="bl-icon-button">forward_circle</button>
						<span>{{ $t('documentsignature.property.counterSignature') }}</span>
					</div>
					<div style="flex: 1;"></div>
					<BlFormSubmit />
				</div>
			</BlForm>
		</div>
	</div>
</template>

<script>
import { Variables, Dialog, Colors } from 'InterfaceBundle'
import { Document } from '../services/Document'

export default {
	name: 'BlDocumentSignatureWindow',
	props: ['controls', 'documentArgs', 'documentRef', 'object', 'duplicateId', 'favContacts', 'body', 'name'],
	data() {
		return {
			opened: true,
			mobile: Variables.mobile,
			formValue: null,
			isFullscreen: false,
			recipientArray: [],
			recommendedRecipients: Array.from(this.favContacts)
		}
	},
	methods: {
		toggleFullscreen($event) {
			$event.stopPropagation()
			if(this.isFullscreen) this.controls.smallscreen()
			else this.controls.fullscreen()
			this.isFullscreen = !this.isFullscreen
		},
		addFromFavorite(index, favorite) {
			this.recipientArray.push(favorite)
			this.recommendedRecipients.splice(index, 1)
		},
		modifyRecipient(index, recipient) {
			Dialog.custom({
				title: this.$t('documentsignature.dialog.modifyRecipient'),
				component: 'BlDocumentSignatureAddRecipientForm',
				componentProps: {recipient: recipient},
			}).then(resp => {
				if(resp.remove) {
					if(this.favContacts.includes(recipient)) this.recommendedRecipients.push(recipient)
					this.recipientArray.splice(index, 1)
				}
				else this.recipientArray[index] = resp
			}).catch(() => {})
		},
		addNewRecipient() {
			Dialog.custom({
				title: this.$t('documentsignature.dialog.addRecipient'),
				component: 'BlDocumentSignatureAddRecipientForm'
			}).then(resp => {
				this.recipientArray.push(resp)
			}).catch(() => {})
		},
		getColor(name) {
			return Colors.get(name)
		},
		openPreview() {
			Document.open(this.documentRef, {id: this.documentArgs.id})
		},
		close(ev) {
			ev.stopPropagation()
			Dialog.confirm({
				title: this.$t('documentsignature.discardDialog.title'),
				accept: this.$t('documentsignature.discardDialog.accept'),
				cancel: this.$t('documentsignature.discardDialog.cancel')
			}).then(() => this.controls.close(false))
		},
		onFormSubmit() {
			this.$refs.form.form.getChild('recipients').setValue(this.recipientArray)
			return true
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-input {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.recipientInfo {
			background-color: var(--bl-background);
			border-radius: 50px;
			padding: 0 7px 0 0px;
			margin: 2px 5px;
			display: flex;
			align-items: center;
			border: 1px solid var(--bl-border);

			icon {
				font-size: 14px;
				margin-left: 5px; 
				color: var(--bl-legend);
			}
		}

		.recipientInfo:hover {
			background-color: #e9e9e9
		}
	}

	.attachments {
		display: flex;
		align-items: center;
		padding: 11px 11px 8px;
		gap: 10px;
		cursor: pointer;
	}

	.favoriteContactLabel {
		font-size: 12px ;
		color: #6d6d6d;
		margin-left: 6px;
	}

	.favoriteContactDisplay {
		display: flex;
		margin-bottom: 5px;
		align-items: center;
		gap: 5px;
		flex-wrap: wrap;
		margin-bottom: 8px;

		.favoriteContact {
			font-size: 12px;
			--bl-chip-color: var(--bl-legend)
		}

		.favoriteContact:hover {
			font-size: 12px;
			--bl-chip-color: #252525;
		}
	}

	.contactHeader {
		border-radius: 50%;
		width: 25px;
		height: 25px;
		text-align: center;
		color: #FFFFFF;
		font-family: "Product sans";
		font-size: 16px;
		line-height: 26px;
		font-weight: 600;
		margin-right: 6px;
	}

	.recipientContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 18px;
	}

	.newSignatureWindowContainer {
		display: flex;
		flex-direction: column;
		border: 1px solid var(--bl-border);
		border-bottom: 0;
		border-top-right-radius: var(--bl-border-radius);
		border-top-left-radius: var(--bl-border-radius);
		background-color: var(--bl-surface);

		.header {
			display: flex;
			align-items: center;
			padding: 2px 0 2px 10px;
			margin-bottom: -6px;
			cursor: pointer;

			icon.legend {
				color: var(--bl-legend);
				font-size: 16px;
				margin-right: 6px;
			}

			h5 {
				font-family: 'Product sans';
				font-size: 14px;
				flex: 1;
				margin: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: normal;
			}

			icon.bl-icon-button {
				font-size: 20px;
				padding: 7px;
			}
		}

		.body {
			flex: 1;
			min-height: 600px;
			display: flex;
			flex-direction: column;

			> form::v-deep > div.bl-card:first-child {
				border: 0;
				border-radius: 0;
				margin: 0;
			}

			hr {
				width: calc(100% - 10px);
			}
		}

		//Custom form styling
		.body::v-deep form {
			display: flex;
			flex: 1;
			flex-direction: column;
		}

		.body::v-deep :is(.bl-input, .bl-input-multiple) {
			margin-bottom: -15px;
			margin-top: -5px;

			input, .bl-input-multiple-content {
				box-shadow: none !important;
			}
		}

		.body::v-deep :is(.bl-input:focus-within, .bl-input__value) label {
			display: none;
		}

		.body::v-deep .blFormFieldRichText {
			.inputContainer {
				box-shadow: none;
				border-radius: 0;
			}

			label {
				display: none;
			}
		}

		.body::v-deep .contentEditableContainer {
			min-height: 400px;
		}

		.body::v-deep form button[type="submit"] {
			float: left;
			margin: 10px;
		}		

		.bodyContainer::v-deep > div {
			height: calc(100% - 10px);
			margin-top: -20px;

			.contentEditableContainer {
				max-height: 400px;
			}
		}

		.bodyContainer::v-deep :is(.blFormFieldRichText, .wysiwyg, .contentEditableContainer) {
			height: 100%;
		}

		.subjectContainer {
			margin: 0 0 3px 0;
		}

		.subjectContainer::v-deep input {
			font-weight: 500;
		}
	}

	.newSignatureWindowContainer.mobile {
		margin-top: -25px;
		margin-left: -25px;
		border: 0;
		height: calc(100% + 50px);

		.body {
			min-height: 0;
			height: 100%;
		}
	}

	.fullscreen {
		height: 100%;
		border: 1px solid var(--bl-border);
		border-radius: var(--bl-border-radius);

		.body {
			min-height: 0;
			height: 100%;
		}
	}

	.bottomActions {
		display: flex;
		padding-left: 5px;
		align-items: center;

		> div.counterSignature {
			display: flex;
			transition: all .2s;
			align-items: center;
			cursor: pointer;
			border-top-left-radius: 14px;
			border-bottom-left-radius: 14px;
			border-top-right-radius: var(--bl-border-radius);
			border-bottom-right-radius: var(--bl-border-radius);

			span {
				display: none;
				font-size: 12px;
				transform-origin: 0 0;
				color: var(--bl-legend);
			}

			button {
				padding: 4px;
			}

			.bl-icon-button {
				margin: -5px 0 -5px -3px;
			}
		}

		> div.counterSignature.active {
			background-color: color-mix(in srgb, var(--bl-surface) 80%, var(--bl-secondary));
			padding-right: 6px;

			span {
				animation: trackingPopIn 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
				display: block;
			}

			.bl-icon-button {
				color: var(--bl-secondary);
			}
		}
	}
</style>